<template>
    <v-dialog
        v-model="dialog"
        hide-overlay
        persistent
        width="300"
        >
        <v-card
            color="primary"
            dark
        >
            <v-card-text>
            Cargando Información
            <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
            ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Storage from "@/modules/storage";

export default {
    name: "Loader",
    data() {
        return {
            dialog: true,
        }
    },
    created() {
        const store = new Storage();
        const storeNames = [
            "country",
            "state",
            "city",
            "currency",
            "EPS",
            "AFP",
            "CCF",
            "ARL",
            "company",
            "user",
            "office",
            "contributor",
            "economicActivityGroup",
            "economicActivity",
            "branch",
            "affiliate",
            "collection",
            "filem"
        ];
        const storeSchemas = [
            "public",
            "public",
            "public",
            "public",
            "public",
            "public",
            "public",
            "public",
            "misalud",
            "misalud",
            "misalud",
            "misalud",
            "public",
            "public",
            "misalud",
            "misalud",
            "misalud",
            "misalud"
        ];
        let storeCount = storeNames.length;
        store
        .deleteDB()
        .then(() => {
            store.createDB(storeNames, storeSchemas);
            store.downloadData("misalud", "company").then(() => {
                store.downloadData("misalud", "office").then(() => {
                    storeCount--;
                });
                store.downloadData("misalud", "user").then(() => {
                    storeCount--;
                });
                store.downloadData("misalud", "contributor").then(() => {
                    storeCount--;
                });
                store.downloadData("misalud", "branch").then(() => {
                    storeCount--;
                });
                store.downloadData("misalud", "affiliate").then(() => {
                    storeCount--;
                });
                store.downloadData("misalud", "collection").then(() => {
                    storeCount--;
                });
                store.downloadData("misalud", "filem").then(() => {
                    storeCount--;
                });
                storeCount--;
            });
            store
            .downloadData("public", "country", {
                where: "_id=" + localStorage.getItem("user.country")
            })
            .then(data => {
                storeCount--;
                const ISOCode = data[0].ISOCode;
                const currency = data[0].currency;
                store
                .downloadData("public", "economicActivityGroup", {
                    where: "countryCode=" + ISOCode
                })
                .then(() => {
                    storeCount--;
                });
                store
                .downloadData("public", "economicActivity", {
                    where: "countryCode=" + ISOCode
                })
                .then(() => {
                    storeCount--;
                });
                store
                .downloadData("public", "ARL", {
                    where: "countryCode=" + ISOCode
                })
                .then(() => {
                    storeCount--;
                });
                store
                .downloadData("public", "EPS", {
                    where: "countryCode=" + ISOCode
                })
                .then(() => {
                    storeCount--;
                });
                store
                .downloadData("public", "AFP", {
                    where: "countryCode=" + ISOCode
                })
                .then(() => {
                    storeCount--;
                });
                store
                .downloadData("public", "CCF", {
                    where: "countryCode=" + ISOCode
                })
                .then(() => {
                    storeCount--;
                });
                store
                .downloadData("public", "state", {
                    where: "countryCode=" + ISOCode
                })
                .then(() => {
                    storeCount--;
                });
                store
                .downloadData("public", "city", {
                    where: "countryCode=" + ISOCode
                })
                .then(() => {
                    storeCount--;
                });
                store
                .downloadData("public", "currency", {
                    where: "code=" + currency
                })
                .then(() => {
                    storeCount--;
                });
            });
        })
        .catch(e => {
            console.log(e);
        });

        const storeInterval = setInterval(() => {
        if (storeCount <= 0) {
            clearInterval(storeInterval);
            this.$root.$router.push({ name: "Home" });
        }
        }, 30);
    },
}
</script>